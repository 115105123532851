import Image from "next/image";
import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import StarsRating from "../StarsRating";
import {
  fetchTestimonial,
  fetchRequiredMedia,
} from "../../services/dynamicDataFetch";
import { strapiImageUrl } from "../../services/generateImageUrl";
import BgStarAnimation from "../BgStarAnimation";
import AnimationTestimonial from "../animations/Testimonial";
import { SectionWithHeadingDesc } from "../layouts/sections";

const ServiceTestimonial = () => {
  const [expanded, setExpanded] = useState(false);
  const [TestimonialDetails, setTestimonialDetails] = useState<any>(null);
  const [TestimonialImage, setTestimonialImage] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchTestimonial();
      const image = await fetchRequiredMedia("testimonial");
      setTestimonialDetails(response.data);
      setTestimonialImage(
        image?.data?.[0]?.attributes?.Media?.media?.data?.attributes?.url
      );
    };
    fetchData();
  }, []);

  return (
    <SectionWithHeadingDesc
      ngClass="relative bg-no-repeat bg-cover py-20 flex items-center justify-center flex-col "
      textHeader={`What our client says about us`}
      highlightText={`client`}
      description={""}
      tHeadColor={"text-white"}
    >
      {/* <BgStarAnimation /> */}
      <div className="flex flex-col lg:flex-row gap-12 h-full items-center md:py-20">
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          navigation={{ nextEl: ".swiper-button-next" }}
          pagination={{ clickable: true }}
          loop={true}
          centeredSlides={true}
          grabCursor={true}
          className="lg:max-w-[710px] max-w-[80vw] order-2 lg:order-1"
        >
          {TestimonialDetails?.map((testimonial: any, index: number) => (
            <SwiperSlide key={index}>
              <div key={index} className="relative flex items-center">
                <div className="mb-10">
                  <div className="lg:relative flex justify-center lg:ml-24">
                    <div className="relative bg-white bg-opacity-[8%] py-[50px] lg:pl-[100px]  lg:px-[40px] px-6 rounded-[5px_50px_5px_50px] lg:w-[554px] ">
                      <div className="flex flex-col gap-5 text-white">
                        <div className="flex sm:flex-row flex-col sm:justify-between sm:items-end">
                          <div className="flex flex-col gap-[6px] ">
                            <h3>{testimonial?.attributes?.name}</h3>
                            <p>{testimonial?.attributes?.title}</p>
                          </div>
                          <StarsRating
                            stars={testimonial?.attributes?.rating || 5}
                          />
                        </div>

                        <p>
                          {expanded
                            ? testimonial?.attributes?.description
                            : testimonial?.attributes?.description?.substring(
                                0,
                                200
                              )}
                          {testimonial?.attributes?.description?.length >
                          200 ? (
                            expanded ? (
                              <span
                                onClick={() => setExpanded(!expanded)}
                                className="text-secondary cursor-pointer font-bold"
                              >
                                {" "}
                                See less
                              </span>
                            ) : (
                              <span
                                onClick={() => setExpanded(!expanded)}
                                className="text-secondary cursor-pointer font-bold"
                              >
                                {" "}
                                ...See more
                              </span>
                            )
                          ) : null}
                          {/* {expanded? testimonial?.desc :
                                    testimonial?.desc.substring(0,270)}

                                    {expanded? <span onClick={() => setExpanded(!expanded)} className='text-secondary-500 text-body-mbl cursor-pointer'> See less</span>:
                                    <span onClick={() => setExpanded(!expanded)} className='text-secondary-500 text-body-mbl cursor-pointer'> ...See more</span>} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden lg:block absolute lg:top-1/2 -translate-y-1/2 lg:left-[35px]">
                  {testimonial?.attributes?.image?.data?.attributes?.url && (
                    <Image
                      className="rounded-full"
                      src={strapiImageUrl(
                        testimonial?.attributes?.image?.data?.attributes?.url
                      )}
                      alt="image"
                      height={130}
                      width={130}
                    />
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className=" hidden lg:block">
            <div className=" swiper-button-next" />
          </div>
        </Swiper>

        <div className="order-1 lg:order-2 lg:w-[467px] h-[381px] flex justify-center">
          {<AnimationTestimonial />}
        </div>
      </div>
    </SectionWithHeadingDesc>
  );
};

export default ServiceTestimonial;
