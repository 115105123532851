import FilledStar from '../../assets/images/Testimonial/FilledStar.svg'
import EmptyStar from '../../assets/images/Testimonial/star.svg'
import React from 'react'
import Image from 'next/image'


const Index = ({stars}:any) => {
  const star= 
  
  Array.from({length: 5}, (v, i) => i).map((i,index) => {
    return (
      <div className=" inline-block mr-1" key={index}>
        <Image
          className=""
          src={i < stars ? FilledStar : EmptyStar}
          alt="star"
        />
      </div>
    );
  })
  return (
    <div>
        {star}
       
    </div>
  )
}

export default Index
