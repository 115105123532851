import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export default function AnimationTestimonial() {
  
  const animationContainer = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current!,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require('../../assets/animation/VG_Testimonial.json')
    });
    return () => anim.destroy();
  }, []);

  return (   
        <div className='animation-container' ref={animationContainer} />
    )
}